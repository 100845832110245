<template>
    <div>
        <div class="flex justify-between mb-4">
            <a-button @click="$router.back()" class="mr-2">
                Regresar
            </a-button>
            <a-button target="_blank" :href="`${URL}/api/sections/${$route.params.section_id}/courses/${$route.params.course_id}/lessons-report?token=${token}`">
                Generar PDF
            </a-button>
        </div>
<!--        <a-card class="mt-2" v-if="courseSection !== null">-->
<!--            <template #header>-->
<!--                <div class="flex justify-between mb-3 mt-2 items-center">-->
<!--                    <h3>Inasistencias</h3>-->
<!--                </div>-->
<!--            </template>-->

<!--            <div class="-my-2 py-2 mt-3 overflow-y-auto">-->
<!--                <div class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg overflow-y-auto border-b border-gray-200 bg-white">-->
<!--                    <table class="min-w-full overflow-y-auto">-->
<!--                        <thead>-->
<!--                            <tr>-->
<!--                                <th class="text-left p-2">Alumno</th>-->
<!--                                <th class="text-left p-2">Tema</th>-->
<!--                                <th class="text-left p-2">Fecha</th>-->
<!--                            </tr>-->
<!--                        </thead>-->
<!--                        <tbody>-->
<!--                            <tr v-for="(absence) in ((courseSection || {}).absences || [])" :key="absence.id">-->
<!--                                <td class="text-left p-2">-->
<!--                                    {{ absence.student.first_name }} {{ absence.student.last_name }}-->
<!--                                </td>-->
<!--                                <td class="text-left p-2">{{ absence.lesson.subject }}</td>-->
<!--                                <td class="text-left p-2">{{ absence.lesson.date }}</td>-->
<!--                            </tr>-->
<!--                        </tbody>-->
<!--                    </table>-->
<!--                </div>-->
<!--            </div>-->
<!--        </a-card>-->
        <a-table :source="courseSection.absences" :columns="columns" :loading="loading">

        </a-table>
    </div>
</template>


<script>
import { URL } from '@/constants';
import { mapActions, mapState } from 'vuex';
const token = localStorage.getItem(process.env.VUE_APP_USER_TOKEN_NAME)
export default {
    computed: mapState({
        courseSection: state => state.sections.courseSection,
        loading: state => state.sections.loading,
        user: state => state.session.user,
        columns() {
            return [
                { title: 'Alumno', mutate: ({key}) => (key.student.first_name + ' ' + key.student.last_name) },
                { title: 'Tema', key: 'lesson.subject' },
                { title: 'fecha', key: 'lesson.date' }
            ]
        }
    }),
    data: () => ({
        periods: {
            first: [],
            second: []
        },
        lessons: [],
        URL,
        token
    }),
    methods: {
        ...mapActions({
            fetchCourseSection: 'sections/fetchCourseSections'
        }),
        mapNotesBy(absence, cut, lesson) {
            return (((this.lessons.filter(it => it.period_cut === cut)
                .find(it => it.id === lesson?.id) || {})
                .students || [])
                .find(it => (it.id === absence.student.id) && it.pivot.lesson_id === lesson.id) || {})?.pivot?.note ?? 'S/N'
        },
        getSemesterName() {
            switch (this.courseSection?.semester?.number) {
                case 1: return "Primer";
                case 2: return "Segundo";
                case 3: return "Tercer";
                case 4: return "Cuarto";
                case 5: return "Quinto";
                case 6: return "Sexto";
                case 7: return "Septimo";
                case 8: return "Octavo";
                case 9: return "Noveno";
                case 10: return "Décimo";
                case 11: return "Décimo primer";
                case 12: return "Décimo segundo";
                case 13: return "Décimo tercer";
                case 14: return "Décimo cuarto";
                case 15: return "Décimo quinto";
                default: this.courseSection?.semester?.number
            }
        }
    },
    watch: {
        courseSection(val) {
            if (val) {
                this.periods = {
                    first: val.lessons.filter(it => it.period_cut === 1),
                    second: val.lessons.filter(it => it.period_cut === 2)
                }
                this.lessons = val.lessons
            }
        }
    },
    created() {
        this.fetchCourseSection({ ...this.$route.params, filters: { with: 'absences.student,absences.lesson,lessons.students,section,course,semester' } })
    }
}
</script>
